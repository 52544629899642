@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables";
@import "node_modules/bootswatch-sass/sandstone/_variables";

$btn-default-bg:#3E3F3A;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$border-radius-base:0px;
$border-radius-large:0px;
$border-radius-small:0px;

//Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootswatch-sass/sandstone/_bootswatch";
@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss";

//Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700);

.form-group{
  margin-bottom:10px;
  > .control-label{
    margin-bottom:2px;
  }
}
.form-control{
  border-radius:0px;
  padding:6px 8px;
  height:40px;
}

.btn{
  padding:6px 16px;
  line-height:28px;
}

.font-mini{
  font-size:9px;
}

.font-tiny{
  font-size:10px;
}

.font-small{
  font-size:12px;
}

.font-medium{
  font-size:14px;
}

.font-large{
  font-size:18px;
}

.panel{
  .panel-title{
    font-size:13px;
    line-height:30px;
    .btn{
      padding-top:0px;
      padding-bottom:0px;
      line-height:30px;
    }
    .form-control{
      height:30px;
      padding-top:0px;
      padding-bottom:0px;
    }
  }
}

.ActionElement__withSelected{
    position:relative;
    max-width:300px;
    > label{
      position: absolute;
      left:-85px;
      top:0px;
      font-size:10px;
    }
}